<template>
    <div>       
        <transition 
            appear
            name="animate__animated animate__bounce"
            enter-active-class="animate__fadeInLeft"
        >   
            <div v-show="aboutUs_Flag" class="companyIntroduction">
                <span style="font-size: 30px; color: blue; ">企业介绍</span> <br>                
                <div >
                    <p style="font-size: 27px;text-align:center;margin-top: 30px;color:blue">了解航图</p>
                    <div class="introduce"> 
                        <p>
                            联众航图是一家新兴的低空经济领域企业，成立于2024年，致力于提供创新的气象服务，特别专注于低空飞行领域的天气预测和气象数据分析。随着低空经济的快速发展，联众航图将高精度气象监测技术与飞行器操作需求紧密结合，打造了一个以气象数据为核心的智能服务平台，旨在为低空飞行器提供实时、安全的气象支持，保障飞行安全和运营效率。
                        <!-- </p> -->
                        <!-- <p> -->

                        </p>
                        <p>
                            核心业务：

气象监测与预测：联众航图利用先进的气象技术和数据分析，提供精确的天气预报和实时监控，帮助低空飞行器运营商在飞行过程中应对复杂的气象变化，确保飞行安全。
飞行风险评估：通过大数据分析和气象模拟，提供低空飞行风险评估，为飞行器提供全面的安全保障。
低空飞行环境数据平台：建立了一个集成的气象数据平台，支持无人机、航空器等低空飞行器实时获取环境信息，提升飞行效率与安全性。
定制化气象解决方案：根据不同客户需求，提供定制化的气象数据服务，帮助行业客户解决特定的气象问题。
                        </p>
                    <p>
                            技术优势：
联众航图依托国内外领先的气象监测技术和大数据分析平台，通过与气象卫星、气象站点等多方合作，实时采集和分析天气数据，为低空飞行器提供准确的气象信息。公司通过云计算和人工智能技术，不仅优化了气象数据的处理效率，还能够预测和模拟低空环境的变化，帮助客户做出更加科学的决策。

使命与愿景：
联众航图致力于成为低空经济领域的气象数据领导者，为全球低空飞行器提供最精准、最可靠的气象服务。公司希望通过科技创新和数据驱动，推动低空经济的安全、高效发展，为智能飞行时代的到来做出贡献。

企业文化：
联众航图秉持着“安全、创新、共赢”的核心价值观，鼓励团队成员不断突破技术和业务的边界，始终保持开放的思维和前瞻性的眼光。我们相信，技术的创新不仅能带来商业的成功，更能推动行业和社会的进步。

未来展望：
随着低空经济的逐步成熟，联众航图将进一步扩大业务范围，拓展国际市场，持续提升气象服务的准确性与智能化水平，助力全球低空飞行器运营商提升飞行安全与效率，推动低空经济的可持续发展。
                        </p>
                        
                    </div> 
                    <br>
                 
                </div>           
            </div>
    </transition>
    </div>
</template>

<script>
import {alignTransition} from '@/mixin'

export default {
    name: 'CompanyIntroduce',
    mixins:[alignTransition]
}
</script>

<style scoped>
    .companyIntroduction{
        position: relative;
        left: 5%;      
        width: 80%;
        height: 100%;
        overflow: hidden;
        padding: 30px;
        margin-top: 30px;
    }
    .companyIntroduction .honour li{       
        float: left;
        margin-right: 50px;
        margin-left: 60px;
        margin-top: 50px;
    }
    .companyIntroduction .honour img{
        width: 450px;
        height: 400px;
    }
    li{
        list-style-type: none;
    }
    p{
        /* 216,216,216 */
        color: rgb(0, 0, 0);  
        text-indent: 2em;
    }
    /deep/.el-icon-s-opportunity{
        font-size: 30px;
        color: rgb(255, 255, 129);
    }
    .companyIntroduction .introduce p{
        /* position: relative;
        left: 4%;
        top: -50px; */
        margin-bottom: -10px;
        margin-left: 35px;
    }
</style>