<template>
    <div>
        <HomeHeader/>
        
    </div>
</template>

<script>
import HomeHeader from './home_page/HomeHeader.vue';

export default {
    name: 'HomePage',
    components:{HomeHeader}
}
</script>
